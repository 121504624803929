body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link svg {
    margin-right: 7px;
    font-size: 23px;
}

.nav-link span {
    font-size: 16px;
    vertical-align: middle;
}

.main-sidebar {
    box-shadow: none;
    border-right: 1px solid #ccc;
    z-index: unset;
}

.nav-wrapper {
    box-shadow: 0.125rem 0 0.625rem rgba(90, 97, 105, 0.12);
}

.small-label {
    letter-spacing: .1rem;
    color: #818ea3;
    font-size: .56rem;
    text-transform: uppercase;
    font-weight: 500;
}

.form-control.mini {
    padding: 0px 5px !important;
    height: 30px !important;
}

.page-header .page-title {
    margin-top: 1px;
    font-size: 34px;
}

.cke_chrome {
    border-radius: 8px;
    overflow: hidden;
}

.form-control {
    color: #000;
    font-weight: normal;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.black:after {
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
}
.block-loader {
    display: flex;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.page-loader {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.60);
    display: flex;
    align-items: center;
    justify-content: center;
}


/* faq item*/
.faq_item__question {
    font-size: 20px;
    padding: 10px 0;
    border-bottom: 1px solid #8c949d;
    font-weight: bold;
}

.faq_item__answer {
    padding: 10px;
    font-size: 17px;
    font-weight: 400;
    background: #e3e3e3;
}

.faq_item.deleted, .contacts_item.deleted {
    display: none;
}

.text-uppercase {
    text-transform: uppercase;
}

.font-weight-bold {
    font-weight: bold;
}

#tariff_logo__input {
    display: none;
}

.tariff_logo__label {
    padding: 10px 15px;
    font-size: 17px;
    color: #ffffff;
    background-color: #2ae68b;
    border-radius: 10px;
    cursor: pointer;
}

.deleted {
    display: none;
}


.zonecover {
    box-shadow: 0px 0px 8px -3px #ccc;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
}


.modal.show.fade {
    display: block;
}
.modal {
    background: rgba(0, 0, 0 , 0.40);
}

.list-group-title {
    font-size: 19px;
    font-weight: 500;
    width: 100%;
}
.list-group-item .with-actions {
    display: flex;
}
.list-group-item.list-group-item-action {
    display: flex;
}
.list-group-actions {
    display: flex;
}
.left-side {
    width: 100%;
}
.edit-modal {
    background: #fff;
    box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105 , 0.12);
    border-radius: 10px;
}

.edit-modal .edit-modal-title {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.edit-modal .edit-modal-body {
    padding: 15px;
}


::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.zonecover__title {
    padding: 15px;
    position: relative;
    cursor: pointer;
    display: flex;
    transition: .2s;
    align-items: center;
}

.zonecover__title:hover {
    background: #f8f8f8;
}
.zonecover__title .info-msg {
    background: #9e9e9e;
    color: #fff;
    font-size: 11px;
    border-radius: 38px;
    padding: 3px 7px;
    margin-left: 10px
}

.zonecover__content {
    padding-left: 20px;
    position: relative;
    overflow: hidden;
}

.zonecover__item {
    border-bottom: 1px solid #ccc;
}

.zonecover__item:first-child {
    border-top: 1px solid #ccc;
}
.zonecover > .zonecover__item:first-child {
    border-top: none;
}

.zonecover__content:before {
    position: absolute;
    content: "";
    width: 1px;
    background: #ccc;
    top: -15px;
    left: 19px;
    height: 100%;
}

.zonecover__title:before {
    content: "";
    width: 10px;
    height: 1px;
    background: #ccc;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.zonecover > .zonecover__item > .zonecover__title:before {
    display: none;
}
.zonecover__content.closed {
    height: 0;
}
.zonecover__item:last-child {
    border-bottom: 0;
}
.zonecover__content.open:before {
    background: #007bff;
}

.zonecover__content.open > .zonecover__item > .zonecover__title:before {
    background: #007bff;
}

.zonecover__content:before {
    height: calc(100% - 11px);
}

.add_form {
    padding: 15px;
    border-top: 1px solid #ccc;
}
.zonecover__add.closed {
    height: 0;
    overflow: hidden;
}
.before-icon {
    margin-right: 7px;
}
.zonecover__add__title {
    /*border-radius: 10px;*/
    /*display: inline-flex;*/
    transition: .2s;
}

.zonecover__add__title:hover {
    background: #007bff;
    color: #fff;
}

.zonecover__add__title:hover path {
    stroke: #fff;
}

.zonecover__actions {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    padding: 10px;
}
.border-rad {
    border-radius: 10px;
    overflow: hidden;
}
.card-post__image img {
    width: 100%;
    max-height: 320px;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.file-remove-btn {
    height: 45px;
    width: 45px;
    border-radius: 10px;
    margin-left: 10px;
}

.file-remove-btn * {
    stroke: #fff
}
img {
    max-width: 100%;
}
table td {
    word-break: break-all;
    max-width: 100%;
}

a {
    cursor: pointer;
}

.nav-tabs .nav-link {
    font-size: 17px;
    padding: 11px 28px;
}

.tab-content {
    /*margin-top: 20px;*/
}
.nav-tabs .nav-link {
    background: transparent;
    cursor: pointer;
}
.nav-tabs .nav-link.active {
    background: #f0f0f0;
}
.list-group-title {
    cursor: pointer;
}
.trim-body {
    height: 48px;
    overflow: hidden;
}
.image-size {
    max-height: 400px;
}
.info-input {
    background: #fff;
    border: 1px solid #e1e5eb;
    padding: 4px 0;
    height: 35px;
    border-radius: 6px;
    text-align: center;
    min-width: 40px;
}

.icon-primary {
    font-size: 30px;
    margin-right: 10px;
    color: #0c5460;
}

.img-overlay {
    background: url(./images/png_overlay.png) center center no-repeat;
    background-size: cover;
    padding: 5px;
}

.user_acc {
    width: 35px;
    height: 35px;
    background-color: #00abc9;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 18px;
    position: relative;
}
.user_acc:hover .user_actions {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    pointer-events: all;
}


.user_actions {
    position: absolute;
    top: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #ffffff;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(15px);
    transition: 0.3s;
    pointer-events: none;
}
.user_actions__item {
    color: #212121;
    font-size: 15px;
    text-align: center;
    padding: 10px 20px;
    background-color: #EFF1F5;
    transition: 0.3s;
}

.user_actions__item:hover {
    background-color: #007bff;
    color: #f3f3f3;
}

.banner_item {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #007bff;
    border-radius: 10px;
}

.banner_media {
    width: 100%;
    height: 500px;
}

.banner_media img , .banner_media video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner_content h1 {
    font-size: 30px;
    margin-bottom: 0 !important;
}


.banner_content p {
    font-size: 20px;
}